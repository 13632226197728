.img-pixelated {
  image-rendering: pixelated;
}

.active-menu .menu-item-active path {
  fill: black;
}

.selected-flag {
  width: 100% !important;
}

.selected-flag .flag {
  left: 14px
}

.react-tel-input .selected-flag:hover,
.react-tel-input.selected-flag:focus {
  background-color: transparent !important;
}

.react-tel-input .input-invalid {
  border: 1px solid red !important;
}

.css-100dgzs-control {
  border-color: rgb(209 213 219/var(--tw-border-opacity));
  border-width: 1px;

}

.dark .css-100dgzs-control {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99/var(--tw-border-opacity));
}

.css-3iigni-container {

  border-radius: 8px;
}

.profile-m-width {
  max-width: 180px;
}

.max-w-230 {
  max-width: 230px;
}

.min-w-230 {
  min-width: 230px;
  ;
}

.max-w-100 {
  max-width: 100px;
}

@media (min-width:640px) {
  .dialog-content {
    /* margin: 5% 10px; */
    margin: 0 !important;
  }
}

th.text-center {
  text-align: center !important;
}

.tranceparent.dialog-content {
  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 0;
}

.calendar-close-btn {
  top: 15px;
  right: 15px;
  cursor: pointer;
}

.loader.dialog-content {

  background-color: transparent !important;
  box-shadow: none !important;
  margin-top: 0;

}

.loader .close-btn {
  display: none;
}

.disable-select__indicators .select__indicators {
  display: none !important;
}

.border-none .select__control {
  /* border: none;*/
  background-color: transparent;
}

.page-height {
  min-height: calc(100%);
}

/* .collapsed-nav-scroll{
  height: 100%;
  overflow: scroll;
} */

/* Hide the vertical scrollbar */
::-webkit-scrollbar {
  width: 0;
}

/* Hide the horizontal scrollbar (if needed) */
::-webkit-scrollbar-thumb {
  background: transparent;
}

.edit-pen {
  padding: 8px;
  background-color: #fff;
  --tw-border-opacity: lightgray;
  bottom: 10%;
  border-radius: 100%;
  right: 3%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.custome-profile img {
  transition: .3s ease;
}

.custome-profile img:hover {
  opacity: 0.6;
}

.employee-details .close-btn {
  top: 15px;
  right: 18px;
}

.employee-details .close-btn:hover {
  color: #fff;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: rgb(209 213 219) !important;
}

.react-datepicker__input-container input {
  --tw-border-opacity: 1;
  appearance: none;
  border: 1px solid rgb(209 213 219/var(--tw-border-opacity));
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
}